@media all and (min-width: 768px) { // md
  .landing-page {
    .main-screen-container {
      height: 588px;
    }
    .registration-form {
      max-width: 459px;
    }
  }
  .what-is-nucleo-page {
    .main-screen-container {
      min-height: 720px;
    }
    .feature-image {

    }
  }
}
@media all and (min-width: 1024px) { // lg
  .feature-image {
    min-width: 820px;
  }
}
@media all and (min-width: 1280px) { // xl
  .feature-image {
    min-width: 1100px;
  }
}
