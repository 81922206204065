.ds-input::placeholder {
  font-weight: normal;
}
.app-select-input {
  input {
    &:focus {
      box-shadow: none;
    }
  }
}
