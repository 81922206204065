.application-search {
  min-width: 20rem;
  max-width: 20rem;

  margin-left: 50%;
  left: -10rem;

  // transition: min-width ease-in-out .3s;

  &.focused {
    min-width: 60rem;
    max-width: 60rem;
    left: -30rem;
  }
}

//@media all and (min-width: 768px) { // md
//  .application-search {
//    min-width: 24rem;
//    max-width: 24rem;
//
//    left: -12rem;
//  }
//}
@media all and (min-width: 1280px) { // xl
  .application-search {
    min-width: 28rem;
    max-width: 28rem; // max-w-md

    left: -14rem;

    &.focused {
      min-width: 60rem;
      max-width: 60rem;
      left: -30rem;
    }
  }
}


