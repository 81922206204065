.avatar-min {
  min-width: 3.5rem;
}

.textarea-height {
  min-height: 2rem;
  transition: height .25s ease-in-out;
}

.preview-content {
  max-width: 45rem;
}
