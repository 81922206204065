.react-select-option.weight {
  display: flex;
  flex-direction: row;
  transition: background 100ms;

  &:hover {
    // transition-delay: 60ms;
    background: #deebff;
  }

  &--is-focused {
    background: none;
  }

  &--is-selected {
    // todo: test if applied correctly
    @apply bg-main-500 #{!important};
  }

  // todo: use css variables for colors
  &.nesting-1 {
    padding-left: 0.5rem;
    // background-color: #F4FEF9; // neutral-75
    // font-weight: bold;
  }
  &.nesting-2 {
    padding-left: 2rem;
    // background-color: #F0F3FF; // neutral-50
    // font-weight: 600;
  }
  &.nesting-3 {
    padding-left: 3.5rem;
  }
  &.nesting-4 {
    padding-left: 5rem;
  }
  &.nesting-5 {
    padding-left: 6rem;
  }
}
