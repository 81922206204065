@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-sans;
}

/* Scrollbar styles START */
.scrollbar-wrapper {
  margin-right: 6px;
}
.scrollbar-wrapper::-webkit-scrollbar {

  width: 4px;
  cursor: pointer;
  padding-left: 4px;
  border-radius: 5px;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}

.scrollbar-wrapper::-webkit-scrollbar:hover {
  /*width: 8px;*/
}

.scrollbar-wrapper::-webkit-scrollbar-track {
  background-color: #fff;
  transition: background-color .3s;
  cursor: pointer;
  /*background: red;*/
}
.scrollbar-wrapper::-webkit-scrollbar-track:hover {
  background-color: #F6F7F8;
}

.scrollbar-wrapper::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #D1D5DB; /* #E5E7EB */
  width: 4px;
  height: 32px;
  border-radius: 5px;
  /*outline: 1px solid slategrey;*/
}
.scrollbar-wrapper:hover::-webkit-scrollbar-thumb:vertical {
  /*width: 8px;*/
  margin-right: 2px;
}
/* Scrollbar styles END */

.tooltip-global-styles {
  background-color: theme(colors.main.500) !important;
  border-radius: 6px !important;

  font-family: Inter, 'sans-serif' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  padding: 0.5rem !important;
}
